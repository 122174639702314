import { useEffect } from "react";
import styled from "styled-components";
import ShoppingCartContent from "../components/PagesComponents/ShoppingCartContent";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as settingsActions from "../redux/actions/settingsActions";
import * as userActions from "../redux/actions/userActions";
import * as cartActions from "../redux/actions/cartActions";
import { useNavigate } from "react-router-dom";
const Container = styled.div`
  display:flex ;
  flex:1 ;
  flex-direction:column ;

  justify-content:space-between ;
  max-width: 1280px;
`;
function ShoppingCart(props) {
  const navigate = useNavigate();
  useEffect(()=>{
    props.actions.getSettings()
    const renderPage = async () => {
      await props.actions.getSettings();
      const access_token = localStorage.getItem("access_token");
      if (access_token) await props.actions.getUser();
      await props.actions.getCartList();
    };
    renderPage();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[])
  useEffect(()=>{
    if (!props.currentUser.id ) {
      navigate("/account/login")
    }
  },[props.currentUser.id,navigate])
  return (
    <Container >
      {props.cart.length > 0 ? (
        <ShoppingCartContent/>
      ) : (
        <p>Empty Cart</p>
      )}
      
    </Container>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getSettings:bindActionCreators(settingsActions.getSettings,dispatch),
      getUser: bindActionCreators(userActions.getUser, dispatch),
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    currentUser: state.currentUserReducer,
    cart: state.cartActionReducer,
  };
}
export default connect(mapStateToProps,mapDispatchToProps)(ShoppingCart) ;
