import { useEffect, useState } from "react";
import { Badge } from "@material-ui/core";
import { Person, ShoppingBasket, Info, ShoppingCartOutlined } from "@material-ui/icons";
import Autocomplete from "./AutoComplete";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import MenuBar from "../MenuComponents/MenuBar";
//#region STYLES
const StickyContent = styled.div`
  display: flex;
  flex: 1;
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  position: ${(props) => (props.isSticky ? "sticky" : "relative")};
  top: 0px;
  z-index: 10;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0 2px 0px rgba(0, 0, 0, 0.1)"
      : "0 0px 0px rgba(0, 0, 0, 0)"};
  opacity: ${(props) =>
    props.isSticky ? 1 : 0}; /* Başlangıçta görünmez olacak */
  transition: opacity 0.45s ease;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
const Element = styled.div`
  padding-inline: 5px;
`;
const LogoImage = styled.img`
  flex: 1;
  border-radius: 5px 5px 0 0;
  height: ${(props) => (props.isScrolled ? "3rem" : "5.2rem")};
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: height 0.3s ease;
`;
const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  //margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color: black;
    text-decoration-line: none;
  }
`;
const AccountInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  //margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
`;
const AccountInfoTest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  //margin-left: 1rem;
`;

const SearchBar = styled.div`
  display: flex;
  padding-inline: 2rem !important;
  margin-bottom: 1rem !important;
`;
//#endregion
function NavBar(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [y, setY] = useState(0);
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    props.actions.getCartList();
  }, [props.actions]);
  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
      //if (y > window.scrollY) {
        //setIsSticky(true);
      //} else {
       // setIsSticky(false);
      //}

      if (window.scrollY > 0) {
        !isScrolled && setIsScrolled(true);
      } else {
        isScrolled && setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);
  return (
    <StickyContent isSticky={isSticky} isScrolled={isScrolled}>
      <Container>
        <Element>
          <MenuBar />
        </Element>
        <Element>
          <LogoImage
            onClick={(_) => {
              navigate("/");
            }}
            isScrolled={isScrolled}
            src={`/catalog/Products/logo.png`}
          ></LogoImage>
        </Element>
        <Element>
          <ButtonSection>
            <AccountInfo
              onClick={() => {
                navigate("/account/login");
              }}
            >
              <Person
                style={{
                  width: "2rem",
                  height: "2rem",
                  color: "#EF1313",
                }}
              />
            </AccountInfo>
            <AccountInfoTest onClick={()=> {
                    navigate("/checkout/cart")
                    /*
                    if(props.currentUser.id) {
                        if(props.cart.length > 0) {
                          navigate("/checkout/cart")
                        } else{
                          setAlertMessage("Please add product to your shopping cart !")
                          setShowAlert(true)
                        }
                      }else{
                        navigate("/account/login")
                      } 
                        */                 
                    }}>
              <Badge
                style={{
                  marginBottom: "0px",
                }}
                badgeContent={props.cart !== null && props.cart.length}
                color="secondary"
              >
                <ShoppingCartOutlined
                  style={{ width: "2rem", height: "2rem", color: "#EF1313" }}
                />
              </Badge>
            </AccountInfoTest>
          </ButtonSection>
        </Element>
      </Container>
      <SearchBar>
        <Autocomplete />
      </SearchBar>
    </StickyContent>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
