import { forwardRef, useEffect, useState } from "react";
import { Badge } from "@material-ui/core";
import { Person, ShoppingBasket, Info } from "@material-ui/icons";
import Autocomplete from "./AutoComplete";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as cartActions from "../../redux/actions/cartActions";
import useWindowWidthAndHeight from "../../utilities/hooks/useWindowWidthAndHeight";
import MenuBar from "../MenuComponents/MenuBar";
import Announcement from "./Announcement";
import { ShoppingCartOutlined } from "@mui/icons-material";
import { withStyles } from '@material-ui/core/styles';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
//#region STYLES
const AnnouncementDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  position: relative;
  top: 0px;
  z-index: 9;
`;
const StickyContent = styled.div`
  display: flex;
  flex: 1;
  max-width: 1280px;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
  position: ${(props) => (props.isSticky ? "sticky" : "relative")};
  top: 0px;
  z-index: 10;
  box-shadow: ${(props) =>
    props.isScrolled
      ? "0 2px 0px rgba(0, 0, 0, 0.1)"
      : "0 0px 0px rgba(0, 0, 0, 0)"};
  opacity: ${(props) =>
    props.isSticky ? 1 : 0}; /* Başlangıçta görünmez olacak */
  transition: opacity 0.45s ease;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  padding-bottom: 0px;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 769px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
  @media only screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;
//#region Left
const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 2px;
`;

const LogoContainer = styled.div`
  display: flex;
  font-size: 60px;
  font-weight: 300;
  color: #333;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const LogoImage = styled.img`
  flex: 1;
  border-radius: 5px 5px 0 0;
  height: ${(props) => (props.isScrolled ? "3rem" : "5.2rem")};
  object-fit: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: height 0.3s ease;
`;
//#endregion
//#region center
const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 769px) {
    margin-bottom: 1rem;
  }
`;

const SearchBar = styled.div`
  display: flex;
`;

//#endregion
//#region Right
const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
  //margin-top: -1.2rem;
  & p{
    font-size:.8rem;
    color: black !important;
    text-align: left;
    width: 100%;
    margin-bottom: .25rem;
  }
`;

const ButtonSection = styled.div`
  display: flex;
  align-items: center;
`;

const AccountInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  & a {
    color: black;
    text-decoration-line: none;
  }
`;
const AccountInfoText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
`;
const AccountInfoTest = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => (props.currentUser.id ? "#30B70E" : "#ef1313")};
  padding: 0.8rem;
  cursor: pointer;
  & h4 {
    text-align: center;
    color: white !important;
  }
`;
const AccountInfoTextTest = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8rem;
  margin-left: 10px;
  letter-spacing: 1px;
  cursor: pointer;
  color: #fff;

`;
//#endregion
//#endregion
function NavBar(props) {
  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const StyledBadge = withStyles((theme) => ({
    badge: {
      backgroundColor: props.currentUser.id ? "#30B70E" : "#ef1313", // Burada istediğiniz rengi belirleyin
      color: '#fff',
     
    },
  }))(Badge);


  const [isScrolled, setIsScrolled] = useState(false);
  const [isSticky, setIsSticky] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [y, setY] = useState(0);
  const navigate = useNavigate();
  const { width } = useWindowWidthAndHeight();
  useEffect(() => {
    props.actions.getCartList();
  }, [props.actions]);
  useEffect(() => {
    const handleScroll = () => {
      setY(window.scrollY);
      if (y > window.scrollY) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      if (window.scrollY > 0) {
        !isScrolled && setIsScrolled(true);
      } else {
        isScrolled && setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);
  return (
    <>
      {width > 768 && (
        <AnnouncementDiv>
          <Announcement />
        </AnnouncementDiv>
      )}
      <StickyContent isSticky={isSticky} isScrolled={isScrolled}>
        <Container>
          <Left>
            <LogoContainer
              onClick={(_) => {
                navigate("/");
              }}
            >
              <LogoImage
                isScrolled={isScrolled}
                src={`/catalog/Products/logo.png`}
              ></LogoImage>
            </LogoContainer>
            {width <= 768 && (
              <ButtonSection>
                {/* SHOP ICON*/}
                {true && (
                  <AccountInfo>
                    <Person
                      style={{
                        width: "1.6rem",
                        height: "1.6rem",
                        color: "#EF1313",
                      }}
                    />
                    <AccountInfoText
                      onClick={() => {
                        navigate("/account/login");
                      }}
                    >
                      <h4>Account</h4>
                      <p>Login/Register</p>
                    </AccountInfoText>
                    {/*
                    <DropDownMenu
                    flexDirection="column"
                    icon={<Person />}
                    text={"ACCOUNT"}
                    contentWidth="10rem"
                    fontSize="0.8rem"
                    fontWeight="400"
                    right="0.8rem"
                    top="3.2rem"
                    minHeight="10vh"
                    contentLinks={<AccountMenuContent />}
                  />
                    */}
                  </AccountInfo>
                )}
                {true && (
                  <AccountInfoTest  onClick={()=> {
                    navigate("/checkout/cart")
                    /*
                    if(props.currentUser.id) {
                        if(props.cart.length > 0) {
                          navigate("/checkout/cart")
                        } else{
                          setAlertMessage("Please add product to your shopping cart !")
                          setShowAlert(true)
                        }
                      }else{
                        navigate("/account/login")
                      } 
                        */                 
                    }}>
                    <StyledBadge
                      badgeContent={props.cart !== null && props.cart.length}
                    >
                      <ShoppingBasket
                        style={{
                          width: "1.4rem",
                          height: "1.4rem",
                          color: "#fff",
                        }}
                      />
                    </StyledBadge>
            
                  </AccountInfoTest>
                )}
              </ButtonSection>
            )}
          </Left>
          <Center>
            <SearchBar>
              <Autocomplete />
            </SearchBar>
          </Center>
          {width > 768 && (
            <Right>
              <p>{props.currentUser.id ? props.currentUser.firstname +' '+ props.currentUser.lastname : ''}</p>
              <ButtonSection>
                {/* SHOP ICON*/}
                
                <AccountInfoTest  currentUser = {props.currentUser} onClick={() => {
                     props.currentUser.id ? navigate("/account/order") : navigate("/account/login") ;
                    }}>
                  <h4>{props.currentUser.id ? props.currentUser.companyName : 'Online Order'} </h4>
                </AccountInfoTest>
                <AccountInfo onClick={()=> {
                  navigate("/checkout/cart")
                  /*
                  if(props.currentUser.id) {
                      if(props.cart.length > 0) {
                        navigate("/checkout/cart")
                      } else{
                        setAlertMessage("Please add product to your shopping cart !")
                        setShowAlert(true)
                      }
                    }else{
                      navigate("/account/login")
                    } 
                      */                 
                  }}>
                  <StyledBadge
                      badgeContent={props.cart !== null && props.cart.length}
                    >
                  <ShoppingCartOutlined
                    style={{
                      width: "2.4rem",
                      height: "2.4rem",
                      color: props.currentUser.id ? "#30B70E" : "#EF1313",
                    }}
                  />
                  </StyledBadge>
                </AccountInfo>
              </ButtonSection>
              
            </Right>
          )}
        </Container>
        <MenuBar setOpenStoreLocation={props.setOpenStoreLocation} />
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            open={showAlert}
            autoHideDuration={2000}
            onClose={() => setShowAlert(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}  // Ortada daha büyük görünmesini sağlar
            sx={{ maxWidth: "500px" }}  // Snackbar boyutu genişletildi
          >
            <Alert
              onClose={() => setShowAlert(false)}
              severity="error"
              sx={{ minw: "100%", padding: "18px 16px" }}
            >
              {alertMessage}
            </Alert>
          </Snackbar>
        </Stack>
      </StickyContent>
    </>
  );
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getCartList: bindActionCreators(cartActions.getCartList, dispatch),
    },
  };
}
function mapStateToProps(state) {
  return {
    cart: state.cartActionReducer,
    settings: state.settingReducer,
    currentUser: state.currentUserReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
